<template>
  <div>
    <div class="card py-2 mb-8">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Mobile Toggle-->
          <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
            <span></span>
          </button>
          <!--end::Mobile Toggle-->
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">Search Jobs</h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <!--end::Info-->
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="flex-row-auto offcanvas-mobile w-300px w-xxl-375px" id="kt_todo_aside">
        <div class="card card-custom card-stretch">
          <div class="card-body px-5">
            <b-form @submit="onSubmit" @reset="onReset">
              <h3>Job</h3>
              <div class="form-group mb-8">
                <label class="font-weight-bolder">Job No.</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="jobNo"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Reference No.</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="referenceNo"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Client</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="client"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Job Status</label>
                <select class="form-control form-control-solid form-control-sm" v-model="jobStatus">
                  <option></option>
                  <option v-for="sts in allJobStatus" :key="sts">{{ sts }}</option>
                </select>
              </div>

              <h3 class="mt-2">Trip</h3>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Date between</label>
                <div class="input-group">
                  <input
                    type="date"
                    class="form-control form-control-solid form-control-sm"
                    placeholder="From"
                    v-model="tripFrom"
                  />
                  <span class="input-group-addon">&nbsp;</span>
                  <input
                    type="date"
                    class="form-control form-control-solid form-control-sm"
                    placeholder="To"
                    v-model="tripTo"
                  />
                </div>
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Company</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="tripCompany"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Address</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="tripAddress"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Driver</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-sm"
                  placeholder=""
                  v-model="tripDriver"
                />
              </div>

              <button type="submit" class="btn btn-primary font-weight-bolder mr-2 px-8">Search</button>
              <button type="reset" class="btn btn-clear font-weight-bolder text-muted px-8">Reset</button>
            </b-form>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8">
        <div class="d-flex flex-column flex-grow-1" v-if="paginatedData.length > 0">
          <div class="card card-custom mb-2" v-for="job in paginatedData" :key="job.id">
            <div class="card-body py-5">
              <div class="d-flex align-items-center">
                <div class="col-md-2">
                  <a href="#" @click="openJobDetail(job.id)">{{ job.id }}</a>
                  <job-dropdown class="float-right" :id="job.id" />
                </div>
                <div class="col-md-1">
                  <span class="label label-inline font-weight-bold" :class="getColorByJobStatus(job.status)">
                    {{ job.status }}
                  </span>
                </div>
                <div class="col-md-9">
                  <template v-for="trip in job.trips">
                    <div
                      class="d-flex flex-xl-row flex-lg-row flex-md-row flex-column align-items-center mb-3 p-2 border-bottom"
                      :key="trip.id"
                    >
                      <div class="col-md-1">{{ trip.type }}</div>
                      <div class="col-md-3">{{ trip.address }}</div>
                      <div class="col-md-3">{{ trip.date }} {{ trip.from_time }} - {{ trip.to_time }}</div>
                      <div class="col-md-1">
                        <span class="label label-inline font-weight-bold" :class="getColorByTripStatus(trip.status)">
                          {{ trip.status }}
                        </span>
                      </div>
                      <div class="col-md-2">
                        <button
                          class="btn btn-icon btn-xs btn-light-primary mx-3"
                          v-b-tooltip.hover
                          title="Generate POD"
                          @click="pod(trip.id)"
                        >
                          <i class="flaticon-file-2"></i>
                        </button>

                        <button
                          class="btn btn-icon btn-xs btn-light-danger"
                          v-b-tooltip.hover
                          title="Duplicate trip"
                          v-if="trip.status == 'FAIL'"
                          @click="duplicate(trip.id)"
                        >
                          <i class="flaticon2-refresh"></i>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <pagination v-model="pagination" />
        </div>

        <no-data v-else></no-data>
        <job-full-details ref="modal" :jobid="jobid" />
      </div>
    </div>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

import { ref, reactive, onMounted, computed } from "@vue/composition-api";

import { searchJob, getProofOfDocument, duplicateTrip } from "@/api/job.api";
import { getOmsStatus } from "@/utils/oms-status";
import Pagination from "@/components/general/Pagination";
import JobDropdown from "@/components/general/JobDropdown";
import JobFullDetails from "@/components/general/JobFullDetails";
import Swal from "sweetalert2";
export default {
  components: { Pagination, JobDropdown, JobFullDetails },
  setup(_, { root }) {
    const { allJobStatus, getColorByJobStatus, getColorByTripStatus } = getOmsStatus();

    onMounted(() => {
      new KTOffcanvas(KTUtil.getById("kt_todo_aside"), {
        overlay: true,
        baseClass: "offcanvas-mobile",
        //closeBy: 'kt_todo_aside_close',
        toggleBy: "kt_subheader_mobile_toggle"
      });
    });

    const jobNo = ref("");
    const client = ref("");
    const referenceNo = ref("");
    const jobStatus = ref("");
    const tripFrom = ref();
    const tripTo = ref();
    const tripAddress = ref("");
    const tripCompany = ref("");
    const tripDriver = ref("");

    const resultList = ref([]);
    const modal = ref(null);

    const pagination = reactive({
      currentPage: 1,
      totalRows: computed(() => resultList.value.length),
      showing: computed(() => paginatedData.value.length),
      itemInPage: 10
    });

    const onSubmit = async evt => {
      evt.preventDefault();

      const param = {
        id: jobNo.value,
        client: client.value,
        reference_no: referenceNo.value,
        status: jobStatus.value,
        from: tripFrom.value,
        to: tripTo.value,
        driver: tripDriver.value,
        address: tripAddress.value,
        company: tripCompany.value
      };

      const response = await searchJob(param);
      resultList.value = response.data;
    };

    const onReset = evt => {
      evt.preventDefault();

      jobNo.value = "";
    };

    const paginatedData = computed(() => {
      let index = (pagination.currentPage - 1) * pagination.itemInPage;
      return resultList.value.slice(index, index + pagination.itemInPage);
    });

    const pod = id => {
      getProofOfDocument(id).then(response => {
        const newBlob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `${id}.pdf`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    };

    const jobid = ref();
    const openJobDetail = job => {
      jobid.value = job;
      root.$nextTick(() => modal.value.$refs["jobdetails"].show());
    };

    const duplicate = async id => {
      try {
        Swal.fire({
          title: "Confirm?",
          text: "This will create a new trip with the same information, are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await duplicateTrip(id);
            onSubmit();
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      jobNo,
      client,
      referenceNo,
      jobStatus,
      tripFrom,
      tripTo,
      tripAddress,
      tripCompany,
      tripDriver,

      allJobStatus,
      getColorByJobStatus,
      getColorByTripStatus,

      paginatedData,
      pagination,

      pod,
      jobid,
      modal,
      openJobDetail,
      duplicate,

      onSubmit,
      onReset
    };
  }
};
</script>
>

<style scoped>
.nested-td-no-border {
  border: none;
}
</style>
